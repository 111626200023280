<style scoped lang="scss">
.userinfo {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 38px 30px;
  background-color: #f6f7fc;
  border-radius: 10px;

  .userinfo-avarat {
    display: flex;
    align-items: center;
    padding-bottom: 46px;
    border-bottom: 1px solid #e8eaf3;

    img {
      width: 78px;
      height: 80px;
      border-radius: 50%;
    }

    .userinfo-info {
      margin-left: 28px;
      display: flex;
      display: flex;
      flex-direction: column;

      button {
        display: inline-flex;
        font-size: 14px;
        letter-spacing: 0px;
        color: #6c4ecb;
        margin-left: 13px;
      }

      .username {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #474747;
      }

      .userinfo-id p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #484848;
      }
    }
  }
}

.userinfo-address {
  margin-top: 50px;
}

.userinfo-address-list {
  display: flex;
  margin-bottom: 50px;

  .title {
    width: 86px;
    text-align: right;
    font-size: 14px;
    letter-spacing: 0px;
    color: #666666;
  }

  .value {
    margin-left: 60px;

    font-size: 14px;

    color: #333333;
  }
}

// 编辑数据
.userinfo-edit {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #e8eaf3;

  .userinfo-edit-title {
    line-height: 70px;
    position: relative;
    text-indent: 32px;

    font-size: 16px;
    font-weight: 600;

    color: #1b1b1b;
  }

  .userinfo-edit-title::after {
    content: "";
    width: 5px;
    height: 18px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    left: 18px;
    top: 26px;
  }
}

.userinfo-edit-content {
  max-width: 1200px;
  padding: 37px 0;
  margin: 0 auto;

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin: 6px 0;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    button {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1000;
      border: none;
      color: #ffffff;
      font-size: 14px;
      width: 80px;
      padding: 6px 13px 8px;
      background: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

.form-item-btn {
  margin-top: 60px;
}

// 修改密码
.editpassword {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.45);
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 10000;
}

.editpassword-box {
  width: 659px;
  height: 455px;
  background-color: #ffffff;
  box-shadow: 0px 2px 27px 0px rgba(87, 87, 87, 0.15);
  border-radius: 10px;
  position: absolute;
  z-index: 100001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  .editpassword-box-title {
    height: 68px;
    line-height: 68px;
    background-color: #f6f7fc;
    border-radius: 10px 10px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #1b1b1b;
    position: relative;
    text-indent: 32px;
  }

  .editpassword-box-title::after {
    content: "";
    width: 5px;
    height: 16px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    left: 20px;
    top: 26px;
  }
}

.editpassword-content {
  margin-top: 50px;
  padding: 0 80px;
  box-sizing: border-box;

  .editpwdbox-btn {
    margin-top: 50px;
    padding-top: 26px;
    text-align: end;
    border-top: 1px solid #eeeeee;
  }
}

::v-deep {
  .el-input__inner {
    border: none;
    background-color: #f6f7fc;
    padding: 0 27px;
  }

  .userinfo-edit-list .el-input--medium .el-input__inner {
    line-height: 60px;
    height: 60px;
    font-size: 14px;
    color: #666666;
    border-radius: 10px;
  }

  .editpassword-content .el-input--medium .el-input__inner {
    line-height: 47px;
    height: 47px;
    font-size: 14px;
    color: #666666;
    border-radius: 10px;
  }

  .userinfo-edit-list .el-form-item--medium .el-form-item__label {
    line-height: 60px;
    color: #1b1b1b;
    padding: 0 18px 0 0;
  }

  .editpassword-content .el-form-item--medium .el-form-item__label {
    line-height: 47px;
    color: #1b1b1b;
    padding: 0 18px 0 0;
  }

  .avatarclass .el-form-item__label {
    line-height: 80px !important;
    padding: 0 18px 0 0;
  }

  .form-item-btn .el-button--medium {
    height: 40px;
    border-radius: 8px;
  }
}
</style>
<template>
  <section class="userinfo">
    <div class="userinfo-avarat">
      <img src="../../assets/images/avarat.png" alt="" />
      <div class="userinfo-info">
        <div>
          <span class="username">亡鱼</span>
          <el-button type="text" @click="handelrevise">修改</el-button>
        </div>
        <div class="userinfo-id">
          <p>ID：<span>123456</span></p>
        </div>
      </div>
    </div>

    <div class="userinfo-address">
      <!-- <div class="userinfo-address-list">
        <p class="title">省:</p>
        <div class="value">{{ userinfo.economize }}</div>
      </div>
      <div class="userinfo-address-list">
        <p class="title">市:</p>
        <div class="value">{{ userinfo.market }}</div>
      </div>
      <div class="userinfo-address-list">
        <p class="title">区:</p>
        <div class="value">{{ userinfo.distinguish }}</div>
      </div> -->
      <div class="userinfo-address-list">
        <p class="title">手机号:</p>
        <div class="value">{{ userinfo.phone }}</div>
      </div>
      <div class="userinfo-address-list">
        <p class="title">登录账号:</p>
        <div class="value">{{ userinfo.account }}</div>
      </div>
    </div>

    <div class="userinfo-edit" v-if="editShow">
      <div class="userinfo-edit-title">编辑信息</div>
      <div class="userinfo-edit-content">
        <div class="userinfo-edit-list">
          <el-form label-width="80px" :model="userinfoedit">
            <el-form-item label="昵称">
              <el-input
                v-model="userinfoedit.nickname"
                placeholder="请输入昵称"
              ></el-input>
            </el-form-item>
            <el-form-item label="头像" class="avatarclass">
              <div class="avatar">
                <el-upload
                  :show-file-list="false"
                  action=""
                  :http-request="uploadImage"
                  :before-upload="beforeAvatarUploada"
                  :on-success="handleAvatarSuccess"
                >
                  <img :src="userinfoedit.avatarUrl" />
                </el-upload>

                <el-button @click="handeleditavatar">修改头像</el-button>
              </div>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                type="password"
                v-model="userinfoedit.password"
                @focus="handeleditPWd"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="省">
              <el-input
                v-model="userinfoedit.economize"
                placeholder="请输入所在省"
              ></el-input>
            </el-form-item>
            <el-form-item label="市">
              <el-input
                v-model="userinfoedit.market"
                placeholder="请输入市"
              ></el-input>
            </el-form-item>
            <el-form-item label="区">
              <el-input
                v-model="userinfoedit.distinguish"
                placeholder="请输入区"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话号码">
              <el-input
                v-model="userinfoedit.phone"
                placeholder="请输入电话号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录账号">
              <el-input
                v-model="userinfoedit.account"
                placeholder="请输入登录账号"
              ></el-input>
            </el-form-item>

            <el-form-item style="text-align: right" class="form-item-btn">
              <el-button type="info" @click="handelclose">取消</el-button>
              <el-button type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <div class="editpassword" ref="editpassword">
      <div class="editpassword-box">
        <div class="editpassword-box-title">修改密码</div>

        <div class="editpassword-content">
          <el-form label-width="80px" :model="pwdFormData">
            <el-form-item label="原密码">
              <el-input
                v-model="pwdFormData.oldPwd"
                placeholder="请输入原密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input
                v-model="pwdFormData.newPwd"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input
                v-model="pwdFormData.morePwd"
                placeholder="请输入 确认密码"
              ></el-input>
            </el-form-item>

            <el-form-item class="editpwdbox-btn">
              <el-button type="info" @click="handeleditPwdClose"
                >取消</el-button
              >
              <el-button type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { getUserInfo } from "@api/user";

export default {
  name: "",
  data() {
    return {
      editShow: false,
      // 个人信息
      userinfo: {
        economize: "四川省",
        market: "成都市",
        distinguish: "天府新区",
        phone: "18483691540",
        account: "18483691540",
      },

      // 修改个人信息
      userinfoedit: {
        nickname: "亡鱼",
        avatarUrl: require("../../assets/images/avarat.png"),
        paddword: "天府新区",
        economize: "四川省",
        market: "成都市",
        distinguish: "天府新区",
        phone: "18483691540",
        account: "18483691540",
      },

      // 修改密码
      pwdFormData: {
        oldPwd: "",
        newPwd: "",
        morePwd: "",
      },
    };
  },
  created() {
    // 获取初始化数据
    this.initData();
  },
  methods: {
    handelrevise() {
      this.editShow = true;

    },
    handelclose() {
      this.editShow = false;
    },
    handeleditavatar() {

    },
    //初始化数据
    async initData() {

      let { data: userinfo } = await getUserInfo();
      this.userinfo = userinfo;
    },

    // 上传头像
    // 封面上传
    handleAvatarSuccess(response, file, fileList) {
      // this.loadingstate = false;
    },
    // 文件格式验证
    beforeAvatarUploada(file) {
      // this.loadingstate = true;
      let index = file.name.lastIndexOf(".");
      let extension = file.name.substr(index + 1);
      let extensionList = [
        "png",
        "PNG",
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "bmp",
        "BMP",
      ];
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message({
          message: "封面不可超出10M",
          type: "warning",
          center: true,
        });

        return false;
      } else if (extensionList.indexOf(extension) < 0) {
        this.$message({
          message: "当前文件格式不支持",
          type: "error",
          center: true,
        });
        return false;
      }
    },

    uploadImage(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      // 上传图片
      // uploadImg(formData).then((response) => {
      //   const { code, msg, data } = response.data;
      //   if (code == 200) {
      //     console.log(data.src);
      //     this.userInfo.avatar = data.src;
      //     this.userInfo.avatar_url = this.API + "/" + data.src;
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: "msg",
      //     });
      //   }
      // });
    },

    // 点击密码框修改密码
    handeleditPWd() {
      this.$refs.editpassword.style.display = "block";
    },

    handeleditPwdClose() {
      this.$refs.editpassword.style.display = "none";
      this.pwdFormData = {
        oldPwd: "",
        newPwd: "",
        morePwd: "",
      };
    },
  },
};
</script>
